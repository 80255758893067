<template>
	<div>
		<Header />
		<WhyUs />
		<OurGirls />
		<Footer />
	</div>
</template>

<script>
import Header from '@/components/Header';
import WhyUs from '@/components/WhyUs';
import OurGirls from '@/components/OurGirls';
import Footer from '@/components/Footer';

export default {
	name: 'Home',
	components: {
		Header,
		WhyUs,
		OurGirls,
		Footer,
	},
	created() {
		this.sendPidAndSid();
	},
	methods: {
		async sendPidAndSid() {
			if (process.env.NODE_ENV === 'development') return;
			const oSearchParams = new URL(document.location).searchParams;
			const data = `pid=${oSearchParams.get('pid')}&sid=${oSearchParams.get('sid')}&com1=${oSearchParams.get('com1')}&com2=${oSearchParams.get('com2')}&com3=${oSearchParams.get('com3')}$referer=${document.referrer}`;
			
			try {
				await fetch('/api/?action=send_partner_info', {
					method: 'POST',
					body: data,
				});
			} catch(error) {
				console.log(error);
			}
		}
	}
}
</script>

<style lang="scss">

</style>
